$spinner-width: 20px;

.loading {
  .custom-loader {
    width: 200px;
    height: 200px;
    background-image: url("../assets/loading.svg");
    background-size: cover;
    animation: loader-animation 1.5s infinite;
  }
}

@keyframes loader-animation {
  100% {
    transform: rotate(360deg);
  }
}
