$black100: #191b22;
$black80: #454c59;
$black60: #626b7e;
$black40: #c4c4c4;
$black20: #ededed;
$black10: #fafafb;

$darkgray: #373737;

$pink100: #b00037;
$pink80: #dd0364;
$pink60: #ff0675;
$pink40: #ff55a0;
$pink20: #ffb8d9;

$yellow100: #7c6700;
$yellow60: #ffd505;

$green100: #298170;
$green60: #68cfbb;

$blue100: #001bb0;
$blue80: #0026f3;
$blue60: #4260ff;
$blue20: #a9b7ff;

$primary: $pink60;

$extension-orange: #ea4335;
$extension-blue: #2d90eb;
$extension-darkblue: #4b87e4;

.has-text-black100 {
  color: $black100 !important;
}

.has-text-black80 {
  color: $black80;
}

.has-text-black60 {
  color: $black60;
}

.has-text-black40 {
  color: $black40;
}

.has-text-green {
  color: $green100;
}

.border-color-completed {
  border-color: $green60;

  &:hover {
    box-shadow: 0 0 0 1px inset $green60;
  }
}

.border-color-normal {
  border-color: $black80;
}

.border-color-black60 {
  border-color: $black60;

  &:hover {
    border-color: $black80;
    box-shadow: 0 0 0 1px inset $black80;
  }
}

.border-color-inactive {
  border-color: $black40;

  &:hover {
    box-shadow: 0 0 0 1px inset $black40;
  }
}

.has-background-completed {
  background-color: $green100;
}

.has-background-inactive {
  background-color: $black40;
}

.has-background-black20 {
  background-color: $black20;
}

.has-background-black10 {
  background-color: $black10;
}

.has-text-blue80 {
  color: $blue80;
}

.has-background-pink60 {
  background-color: $pink60;
}

.has-background-extension-orange {
  background-color: $extension-orange;
}

.has-background-extension-blue {
  background-color: $extension-blue;
}

.has-background-extension-darkblue {
  background-color: $extension-darkblue;
}

.has-background-transparent {
  background-color: transparent !important;
  background: transparent !important;
}

.has-background-white-mobile {
  @media only screen and (max-width: $tablet) {
    background-color: white !important;
  }
}

.is-primary[disabled] {
  background-color: $black40 !important;
  opacity: 1 !important;
}
