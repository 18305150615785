.has-border {
  border-width: 1px;
  border-style: solid;
}
.has-border2 {
  border-width: 2px;
  border-style: solid;
}
.has-border-upload {
  border: 1px dashed $black80;
}
.has-border-upload.is-active {
  border: 1px dashed $blue80;
}
.border-radius-small {
  border-radius: 4px;
}
.has-border-radius {
  border-radius: 4px;
}
.column.is-disabled {
  p {
    color: $black80;
  }
}

.is-size-8 {
  font-size: 0.55em;
}

.height-100 {
  max-height: 100vh;
  height: 100vh;
}

.bg-image {
  background: url("../assets/background.svg");
  background-size: 100%;
}

.bg-image2 {
  background: url("../assets/background2.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.overflow-y-auto {
  overflow-y: auto;
}
.dashboard {
  max-height: calc(100vh - 52px);
}

.has-background-extension-default {
  background: url("../assets/fileFormat.svg");
  background-size: 100%;
}

.go-back-hover {
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    color: $black100;
  }
}

.link-back-hover {
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    color: $blue100;
  }
}

.p-6-tablet {
  @media only screen and (min-width: $tablet) {
    padding: 3rem !important;
  }
}

.p-5-mobile {
  @media only screen and (max-width: $tablet) {
    padding: 1.5rem !important;
  }
}

.has-width-130 {
  width: 130px;
}

.image.is-56x56 {
  width: 56px;
  height: 56px;
}

.dropdown {
  .dropdown-trigger {
    .button {
      &:focus {
        outline: 0;
        box-shadow: none;
        .image {
          img {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}

.challenge-bg {
  position: fixed;
  top: 0;
  right: 0;
  img {
    height: 100vh;
  }
}

textarea {
  outline-color: $black60 !important;
  &::placeholder {
    color: $black60 !important;
  }
}

.is-40x40 {
  width: 40px !important;
  height: 40px !important;
}

.is-40x40-mobile {
  @media only screen and (max-width: $tablet) {
    width: 40px !important;
    height: 40px !important;
  }
}

.mt-3-mobile {
  @media only screen and (max-width: $tablet) {
    margin-top: 0.75rem !important;
  }
}

.has-height-32 {
  height: 32px !important;
}

.pt-5-tablet {
  @media only screen and (min-width: $tablet) {
    padding-top: 1.5rem !important;
  }
}

.object-fit-contain {
  object-fit: contain;
}

.text-decoration-none {
  text-decoration: none;
}

.ml-minus {
  margin-left: -8px;
}

.is-round {
  border-radius: 50%;
}

.is-faded {
  opacity: 0.5;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
}

.hover-black {
  &:hover {
    color: black !important;
  }
}

.is-flex-1 {
  flex: 1;
}

.min-height-100 {
  min-height: calc(100vh - 294px - 3rem);
}

.is-justify-content-center-mobile {
  @media only screen and (max-width: $tablet) {
    justify-content: center !important;
  }
}

.is-justify-content-flex-end-tablet {
  @media only screen and (min-width: $tablet) {
    justify-content: flex-end !important;
  }
}

.tags-two-lines {
  height: 3.5em;
  line-height: 1.75em;
}

.is-tag-size {
  font-size: 14px !important;
}

.waiting-bg-date {
  background: url("../assets/waitingDate.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 70%;
}

.waiting-bg-no-date {
  background: url("../assets/unknownDate.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 140%;
  @media only screen and (min-width: $tablet) {
    background-size: 90%;
  }
}

.waiting-height {
  height: calc(100vh - 222px - 52px);

  @media only screen and (min-width: $tablet) {
    height: calc(100vh - 52px);
  }
}
