@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import "../../node_modules/bulma/sass/utilities/initial-variables.sass";
@import "../../node_modules/bulma/sass/utilities/functions.sass";

@import "colors";
@import "helpers";
@import "accordion";
@import "progressbar";
@import "menu";
@import "carousel";
@import "spinner";
@import "loading";

body {
  background-color: $black20;
}

html {
  overflow: hidden !important;
}

$family-primary: "Inter";
$navbar-breakpoint: 800px;

@import "../../node_modules/bulma-helpers/bulma-helpers";
@import "../../node_modules/bulma/bulma";
