.progress-bar {
  height: 8px;
  width: 100%;
  .progress-bar-bg {
    width: 100%;
    height: 100%;
    background-color: $black20;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .progress-bar-progress {
    height: 100%;
    background-color: $green60;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
