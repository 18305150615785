.menu-image {
  width: 100%;
  @media only screen and (min-width: $tablet) {
    width: 280px;
  }

  .menu-image-hover {
    display: none;
    position: absolute;
    top: 10px;
    left: 0;
  }

  &:hover {
    .menu-image-hide {
      visibility: hidden;
    }

    .menu-image-hover {
      display: initial;
    }
  }
}

.menu-list-el {
  a {
    &:hover {
      background-color: initial !important;

      div.panel-active {
        background-color: $pink80 !important;
        svg {
          color: white;
        }
      }
      p.panel-active {
        color: $pink80 !important;
      }
    }
  }
}

.menu-list-shrink {
  position: absolute;
  top: 0px;
  right: 0;
  transform: translateX(100%);
  z-index: 100;
}

.dropdown-menu {
  top: 125% !important;
  border: 1px solid $blue60;

  .dropdown-content {
    border-bottom: 1px solid $blue60;
    border-radius: 0 !important;
    .button {
      border-radius: 0 !important;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }

  .is-hidden-tablet {
    .button {
      pointer-events: none;
      background: $blue20;
      color: $black100;
    }
  }
}
