.accordion-text {
  .accordion-text-min-height {
    min-height: 4.5em;
  }

  .accordion-text-content {
    line-height: 1.5em;
    max-height: 4.5em;
    overflow: hidden;
  }
  .accordion-text-content.accordion-text-expanded {
    max-height: initial;
  }
  .accordion-text-chevron {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
