.carousel-icon {
  position: absolute;
  bottom: 0;
  right: 5px;
  svg {
    color: $black60;
  }
}

.carousel-is-active {
  border-color: $pink60 !important;
  h2 {
    color: $pink100 !important;
  }
}

.carousel-is-active .carousel-icon {
  svg {
    color: $pink60 !important;
  }
}
